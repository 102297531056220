import React from "react"
import cx from "classnames"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import * as styles from "../assets/styles/youme.module.scss"
import { IMG } from "../const"

const FeedbackSection = loadable(() => import("../components/feedback-section"))

const Image = ({ imgNumber, altText, height }) => {
  const imgSrc = `${IMG.YOUME}/youme-${imgNumber}`

  return (
    <picture>
      <source
        media="(max-width: 416px)"
        srcSet={`
          ${imgSrc}_370.avif,
          ${imgSrc}_740.avif 2x,
          ${imgSrc}_1110.avif 3x`}
        type="image/avif"
      />
      <source
        media="(max-width: 416px)"
        srcSet={`
          ${imgSrc}_370.webp,
          ${imgSrc}_740.webp 2x,
          ${imgSrc}_1110.webp 3x`}
        type="image/webp"
      />
      <source
        media="(max-width: 416px)"
        srcSet={`
          ${imgSrc}_370.png,
          ${imgSrc}_740.png 2x,
          ${imgSrc}_1110.png 3x`}
      />

      <source
        media="(min-width: 417px)"
        srcSet={`
          ${imgSrc}_740.avif,
          ${imgSrc}_1480.avif 2x,
          ${imgSrc}_2220.avif 3x`}
        type="image/avif"
      />
      <source
        media="(min-width: 417px)"
        srcSet={`
          ${imgSrc}_740.webp,
          ${imgSrc}_1480.webp 2x,
          ${imgSrc}_2220.webp 3x`}
        type="image/webp"
      />
      <source
        media="(min-width: 417px)"
        srcSet={`
          ${imgSrc}_740.png,
          ${imgSrc}_1480.png 2x,
          ${imgSrc}_2220.png 3x`}
      />
      <img
        src={`${imgSrc}_1110.png`}
        className={styles.img}
        alt={altText}
        width="560"
        height={height}
        loading="lazy"
      />
    </picture>
  )
}

const Youme = () => {
  return (
    <Layout pageTitle="YouMe: a&nbsp;new social network">
      {() => {
        return (
          <>
            <main className={styles.main}>
              <section className={styles.youmeTop}>
                <div className={styles.wrapper}>
                  <div className={styles.youmeTopContainer}>
                    <div className={styles.topContent}>
                      <div className={styles.logoContainer}>
                        <img
                          src={`${IMG.YOUME}/youme-logo.svg`}
                          className={styles.topLogoImg}
                          alt="YouMe logo"
                          width="96"
                          height="96"
                        />
                      </div>
                      <h1 className={styles.topTitle}>YouMe Social</h1>
                      <p className={styles.topText}>
                        YouMe.Social is a&nbsp;new social network. You are welcome to
                        sign up and enjoy all its features.
                      </p>
                    </div>
                    <div className={styles.topImgContainer}>
                      <div className={styles.topImgInternal}>
                        <img
                          src={`${IMG.YOUME}/youme-1.svg`}
                          className={styles.topImg}
                          alt="People with devices"
                          width="336"
                          height="203"
                        />
                        <img
                          src={`${IMG.YOUME}/youme-2.svg`}
                          className={styles.topImg1}
                          alt="Shadow"
                          width="378"
                          height="339"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.youmeSignup}>
                <div className={styles.wrapper}>
                  <div className={styles.youmeSignupContainer}>
                    <div className={styles.textContent}>
                      <div>
                        <h2>Sign up and Connect</h2>
                        <p>
                          To sign up a&nbsp;user needs only to add a&nbsp;name and an&nbsp;email address and create a&nbsp;password.
                        </p>
                        <p>
                          Users can connect by following each other and
                          interacting.
                        </p>
                      </div>
                    </div>
                    <div className={styles.imgContent}>
                      <div className={styles.imgContainer}>
                        <Image
                          imgNumber="4"
                          altText="Signup page"
                          height="445"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.youmePages}>
                <div className={styles.wrapper}>
                  <div className={styles.youmePagesContainer}>
                    <div>
                      <h2>Home page</h2>
                      <p>
                        On the Home page, users see all their friends and
                        followers posts. They can like the post, comment, or
                        share them.
                      </p>
                      <div className={styles.imgContainer}>
                        <Image imgNumber="5" altText="Home page" height="698" />
                      </div>
                    </div>
                    <div>
                      <h2>Discover page</h2>
                      <p>
                        Discover page has every public post on YouMe Social.
                        They can find the most relevant posts based on their
                        interests.
                      </p>
                      <div className={styles.imgContainer}>
                        <Image
                          imgNumber="6"
                          altText="Discover page"
                          height="698"
                        />
                      </div>
                    </div>
                    <div>
                      <h2>My Profile</h2>
                      <p>
                        My profile includes all user posts and their photos, as
                        well as posts and photos they were mentioned or tagged,
                        contact and basic info.
                      </p>
                      <div className={styles.imgContainer}>
                        <Image
                          imgNumber="7"
                          altText="Profile page"
                          height="698"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.youmeGroups}>
                <div className={styles.wrapper}>
                  <div className={styles.youmeGroupsContainer}>
                    <h2>Groups and Collections</h2>
                    <p className={styles.groupsText}>
                      Groups and Collections pages allow users to discover and
                      join different communities:
                      <br /> public, restricted and private.
                    </p>
                    <div className={styles.groupsGrid}>
                      <div>
                        <div className={styles.imgContainer}>
                          <Image
                            imgNumber="8"
                            altText="Group page"
                            height="335"
                          />
                        </div>
                        <h3>Groups can have many users and anyone can post</h3>
                        <div>
                          <p>At the same time, there are several roles within a&nbsp;group:</p>
                          <ul>
                            <li><strong>Owner</strong>&nbsp;— a&nbsp;registered user who created it.</li>
                            <li><strong>Moderator</strong>&nbsp;— a&nbsp;user who can edit, delete posts and ban members.</li>
                            <li><strong>User</strong>&nbsp;— can create posts, comment and like.</li>
                          </ul>
                        </div>
                      </div>
                      <div>
                        <div className={styles.imgContainer}>
                          <Image
                            imgNumber="9"
                            altText="Collections"
                            height="335"
                          />
                        </div>
                        <h3>
                          Collection has one owner, and the only owner can make
                          posts
                        </h3>
                        <div>
                          <p>
                            Users can easily use filters for category and group
                            (collection) type and sort by popularity or date
                            created to find a&nbsp;necessary group (collection).
                            Also, they get recommended and can check other
                            users' communities.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.youmeIt}>
                <div className={styles.wrapper}>
                  <div className={styles.youmeItContainer}>
                    <div className={styles.itItem}>
                      <div className={styles.imgContainer}>
                        <Image
                          imgNumber="10"
                          altText="Search page"
                          height="305"
                        />
                      </div>
                    </div>
                    <div className={styles.itItem}>
                      <div className={styles.itTextBox}>
                        <h2>Search</h2>
                        <p className={styles.itText}>
                          The search function allows users to find what they are
                          looking for. They have to type the wording, the
                          platform suggests the autofill.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.youmeGroups}>
                <div className={styles.wrapper}>
                  <div className={styles.youmeGroupsContainer}>
                    <h2>Personal and Business pages</h2>
                    <p className={styles.groupsText}>
                      Users can have personal and business pages in one account.
                    </p>
                    <div className={styles.groupsGrid}>
                      <div>
                        <div className={styles.imgContainer}>
                          <Image
                            imgNumber="11"
                            altText="Personal pages"
                            height="790"
                          />
                        </div>
                      </div>
                      <div>
                        <div className={styles.imgContainer}>
                          <Image
                            imgNumber="12"
                            altText="Business pages"
                            height="790"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={cx(styles.youmeGroups, styles.v1Bg)}>
                <div className={styles.wrapper}>
                  <div className={styles.youmeGroupsContainer}>
                    <div className={styles.groupsGrid}>
                      <div>
                        <div className={styles.imgContainer}>
                          <Image
                            imgNumber="13"
                            altText="Notifications"
                            height="364"
                          />
                        </div>
                        <h3>Notifications</h3>
                        <div>
                          <p>
                            The platform notifies they about updates: new
                            comments, replies, tags, likes. So users can always
                            stay aware of their interactions on the platform.
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className={styles.imgContainer}>
                          <Image
                            imgNumber="14"
                            altText="Messenger page"
                            height="364"
                          />
                        </div>
                        <h3>Messenger</h3>
                        <div>
                          <p>
                            Each registered user has access to a&nbsp;messenger
                            feature. Users use a&nbsp;messenger to communicate with
                            their friends and followers, receive notifications
                            about new messages and create conversations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.youmeIt}>
                <div className={styles.wrapper}>
                  <div className={styles.youmeItContainer}>
                    <div className={styles.itItem}>
                      <div className={styles.itTextBox}>
                        <h2>Post and Polls</h2>
                        <p className={styles.itText}>
                          Users use YouMe.Social to create posts: upload images
                          and videos, add polls and set its deadline. Pictures
                          and videos make posts more attractive and encourage
                          users to like and comment. Online polls are a&nbsp;way of
                          estimating public opinion.
                        </p>
                      </div>
                    </div>
                    <div className={styles.itItem}>
                      <div className={styles.imgContainer}>
                        <Image imgNumber="15" altText="Post" height="305" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.youmeMobile}>
                <div className={styles.wrapper}>
                  <div className={styles.youmeMobileContainer}>
                    <h2>Mobile designs</h2>
                    <div className={styles.mobGrid}>
                      {[16, 17, 18, 19].map(imageNumber => (
                        <div key={imageNumber}>
                          <div className={styles.imgContainer}>
                            <picture>
                              <source
                                media="(max-width: 416px)"
                                srcSet={`
                                  ${IMG.YOUME}/youme-${imageNumber}_176.avif,
                                  ${IMG.YOUME}/youme-${imageNumber}_352.avif 2x,
                                  ${IMG.YOUME}/youme-${imageNumber}_528.avif 3x`}
                                type="image/avif"
                              />
                              <source
                                media="(max-width: 416px)"
                                srcSet={`
                                  ${IMG.YOUME}/youme-${imageNumber}_176.webp,
                                  ${IMG.YOUME}/youme-${imageNumber}_352.webp 2x,
                                  ${IMG.YOUME}/youme-${imageNumber}_528.webp 3x`}
                                type="image/webp"
                              />
                              <source
                                media="(max-width: 416px)"
                                srcSet={`
                                  ${IMG.YOUME}/youme-${imageNumber}_176.png,
                                  ${IMG.YOUME}/youme-${imageNumber}_352.png 2x,
                                  ${IMG.YOUME}/youme-${imageNumber}_528.png 3x`}
                              />

                              <source
                                media="(min-width: 417px)"
                                srcSet={`
                                  ${IMG.YOUME}/youme-${imageNumber}_352.avif,
                                  ${IMG.YOUME}/youme-${imageNumber}_704.avif 2x,
                                  ${IMG.YOUME}/youme-${imageNumber}_1056.avif 3x`}
                                type="image/avif"
                              />
                              <source
                                media="(min-width: 417px)"
                                srcSet={`
                                  ${IMG.YOUME}/youme-${imageNumber}_352.webp,
                                  ${IMG.YOUME}/youme-${imageNumber}_704.webp 2x,
                                  ${IMG.YOUME}/youme-${imageNumber}_1056.webp 3x`}
                                type="image/webp"
                              />
                              <source
                                media="(min-width: 417px)"
                                srcSet={`
                                  ${IMG.YOUME}/youme-${imageNumber}_352.png,
                                  ${IMG.YOUME}/youme-${imageNumber}_704.png 2x,
                                  ${IMG.YOUME}/youme-${imageNumber}_1056.png 3x`}
                              />
                              <img
                                src={`${IMG.YOUME}/youme-${imageNumber}_1056.png`}
                                className={styles.img}
                                alt="Mobile screen"
                                width="176"
                                height="308"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.youmeComing}>
                <div className={styles.wrapper}>
                  <div className={styles.youmeComingContainer}>
                    <h3>Coming soon products</h3>
                    <p>
                      We are excited to announce the new products’ development:
                      <br /> <b>YMS Photos</b>, <b>YMS Mail</b> and{" "}
                      <b>YMS Telegram</b>
                    </p>
                    <p>And more to come</p>
                  </div>
                </div>
              </section>
            </main>
            <FeedbackSection />
          </>
        )
      }}
    </Layout>
  )
}

export default Youme
